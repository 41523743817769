exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-gatsby-theme-portfolio-minimal-pages-404-tsx": () => import("./../../../src/gatsby-theme-portfolio-minimal/pages/404.tsx" /* webpackChunkName: "component---src-gatsby-theme-portfolio-minimal-pages-404-tsx" */),
  "component---src-gatsby-theme-portfolio-minimal-templates-article-index-tsx": () => import("./../../../src/gatsby-theme-portfolio-minimal/templates/Article/index.tsx" /* webpackChunkName: "component---src-gatsby-theme-portfolio-minimal-templates-article-index-tsx" */),
  "component---src-gatsby-theme-portfolio-minimal-templates-article-listing-index-tsx": () => import("./../../../src/gatsby-theme-portfolio-minimal/templates/ArticleListing/index.tsx" /* webpackChunkName: "component---src-gatsby-theme-portfolio-minimal-templates-article-listing-index-tsx" */),
  "component---src-gatsby-theme-portfolio-minimal-templates-service-index-tsx": () => import("./../../../src/gatsby-theme-portfolio-minimal/templates/Service/index.tsx" /* webpackChunkName: "component---src-gatsby-theme-portfolio-minimal-templates-service-index-tsx" */),
  "component---src-gatsby-theme-portfolio-minimal-templates-service-listing-index-tsx": () => import("./../../../src/gatsby-theme-portfolio-minimal/templates/ServiceListing/index.tsx" /* webpackChunkName: "component---src-gatsby-theme-portfolio-minimal-templates-service-listing-index-tsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-reservar-js": () => import("./../../../src/pages/reservar.js" /* webpackChunkName: "component---src-pages-reservar-js" */)
}

